import get from 'just-safe-get'

export function extendNavigation(navigation = []) {
  if (!Array.isArray(navigation)) return []
  const filterOutCategories = ['home', 'reports']
  const reportsIndex = navigation.findIndex(
    (navItem) => navItem.name === 'reports'
  )
  const reports = get(navigation, `${reportsIndex}.children`) || []
  return [...reports, ...navigation].filter(
    (navItem) => !filterOutCategories.includes(navItem.name)
  )
}

/**
 * Generating an array of unique scopes from a permissions config tree
 * @param {Object} permissionsConfig
 * @returns {String[]}
 */
export const genAccumulatedScopes = (permissionsConfig) => {
  const categoriesPermissions = Object.keys(permissionsConfig)
  const itemsPermissions = Object.entries(permissionsConfig).reduce(
    (acc, [key, value]) => acc.concat(getScopesRecursive(value)),
    []
  )
  return [...new Set([...categoriesPermissions, ...itemsPermissions])]
}

/**
 * Recursively get all the scope keys from a tree
 * @param {Object[]} arr
 * @returns {String[]}
 */
export const getScopesRecursive = (arr) =>
  arr.reduce(
    (acc, item) => [
      ...acc,
      item.scope,
      ...(item.children ? getScopesRecursive(item.children) : [])
    ],
    []
  )

/**
 * Transform array of scopes to object of scope booleans with default value of true
 * @param {String[]} scopesArr
 * @returns {Object}
 */
export function parseScopesToObj(scopesArr = []) {
  return scopesArr.reduce(
    (obj, scope) => ({
      ...obj,
      [scope]: true
    }),
    {}
  )
}

/**
 * Transform an object of scope booleans to array of only the valid ones
 * @param {Object} scopesObj
 * @param {Object} permissionsConfig
 * @returns {String[]}
 */
export function parseScopesToArray(scopesObj = {}, permissionsConfig = {}) {
  const accumulatedScopes = genAccumulatedScopes(permissionsConfig)
  return Object.entries(scopesObj)
    .filter(([key, value]) => value === true && accumulatedScopes.includes(key))
    .map(([key]) => key)
}

/**
 * Get an array of the user permissions names
 * @param {Array} userPermissionsTemplates
 * @returns {String[]}
 */
export function setPermissionTemplateNamesArray(userPermissionsTemplates) {
  if (userPermissionsTemplates?.length) {
    return userPermissionsTemplates.map((permission) => permission.name)
  }
}
