<template>
  <div>
    <el-select
      id="scopes"
      v-model="permissionName"
      data-testid="scopes"
      class="w-full"
      clearable
      :disabled="disabled"
      :placeholder="$t('pages.settings.general.debug.none')"
      @change="selectPresetPermissions"
    >
      <el-option
        value="create_template"
        :label="$t('pages.settings.users.form.scopes.preset.create_template')"
        class="text-th-primary-blue"
      />
      <el-option-group
        v-if="Array.isArray(templates) && templates.length"
        :label="$t('pages.settings.users.permissions.presets.custom')"
      >
        <el-option
          v-for="permission in templates"
          :key="permission.value"
          :value="permission.value"
          :label="permission.label"
        />
      </el-option-group>
      <el-option-group
        v-if="Array.isArray(presets) && presets.length"
        :label="$t('pages.settings.users.permissions.presets.base')"
      >
        <el-option
          v-for="permission in presets"
          :key="permission.value"
          :value="permission.value"
          :label="permission.label"
        />
      </el-option-group>
    </el-select>
    <div class="text-th-primary-blue w-full flex justify-between">
      <div />
      <el-button
        :disabled="!permissionName"
        text
        @click="showPermissionsModal"
        v-text="$t('pages.settings.users.display.permissions.view')"
      />
    </div>
    <modal v-model="isModalOpen" height="90%" width="85%">
      <el-container class="p-8 bg-th-background-color">
        <el-header class="flex justify-between items-center p-0 mb-4 h-8">
          <div class="text-lg" v-text="$t('pages.permissions.title')" />
          <el-button
            text
            class="text-lg text-gray-600"
            icon="Close"
            @click="hideModal()"
          />
        </el-header>
        <el-main class="p-0">
          <permissions-view
            template-type="user"
            :subtitle="$t('pages.permissions.settings.users.subtitle')"
            :template-id="permissionId"
            :resources="resources"
            @scopes-change="handleScopeChange"
          />
        </el-main>
      </el-container>
    </modal>
  </div>
</template>

<script>
import { basePermissions } from './permissions-constants'
import PermissionsView from '@/components/permissions-view'

const basePermissionsArray = Object.entries(basePermissions).map(
  ([name, permission]) => ({
    name,
    ...permission
  })
)

export default {
  components: {
    PermissionsView
  },
  props: {
    modelValue: {
      type: [String, null],
      required: false,
      default: null
    },
    permissionsTemplates: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      permissionName: '',
      permissionId: undefined,
      isModalOpen: false
    }
  },
  computed: {
    presets() {
      return basePermissionsArray.map((p) => ({
        value: p.name,
        label: p.title,
        scopes: p.scopes
      }))
    },
    templates() {
      return this.permissionsTemplates.map((p) => ({
        id: p.id,
        value: p.id,
        label: p.name,
        scopes: p.scopes
      }))
    },
    allPermissions() {
      return [...this.presets, ...this.templates]
    }
  },
  watch: {
    modelValue: 'matchScopesToPermission',
    permissionsTemplates: 'matchScopesToPermission'
  },
  methods: {
    matchScopesToPermission() {
      if (!this.modelValue || !this.permissionsTemplates.length) return
      const permission =
        this.allPermissions.find((p) => p.id === this.modelValue) || {}
      this.permissionName = permission.value || ''
      this.permissionId = permission.id || undefined
    },
    selectPresetPermissions(value) {
      if (value === 'create_template') {
        this.showPermissionsModal()
        this.permissionName = ''
        this.permissionId = undefined
        return
      }
      const selectedPermissions =
        this.allPermissions.find((p) => p.value === value) || {}
      this.permissionId = selectedPermissions.id
      this.permissionName = selectedPermissions.value
      this.$emit('update:modelValue', this.permissionId)
    },
    showPermissionsModal() {
      this.isModalOpen = true
    },
    hideModal() {
      this.isModalOpen = false
    },
    handleScopeChange(templateModel) {
      this.hideModal()
      this.$emit('update:modelValue', templateModel.id)
      this.$emit('refetch-templates')
    }
  }
}
</script>
