<template>
  <div>
    <el-button @click="$thModal.show('connect-staff')">
      {{ $t('pages.settings.users.display.staff_connect.button') }}
    </el-button>
    <th-modal
      width="400px"
      :title="$t('pages.settings.users.connect_staff.dialog.title')"
      name="connect-staff"
    >
      <th-input-title
        :title="
          $t('pages.settings.users.connect_staff.dialog.select.placeholder')
        "
      />
      <remote-search-select
        v-model="selectedMemberName"
        :result-filter="resultFilter"
        :compute-name="getName"
        class="w-full"
        resource="staff"
        data-testid="staff-search"
        popper-append-to-body
        @resource-set="handleStaffChanged"
      />
    </th-modal>
  </div>
</template>

<script>
import getFullName from '@/utils/full-name'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  name: 'ConnectStaff',
  components: {
    RemoteSearchSelect
  },
  props: {
    initiallyConnectedStaff: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      selectedMemberName: undefined
    }
  },
  methods: {
    // results filtered for the ones that doesnt have a connected user and have a full name
    // or where the previous selected before removing
    resultFilter(results) {
      return results
        .filter(
          (r) =>
            (!r.user && !r.deleted && !!getFullName(r)) ||
            r.id === this.initiallyConnectedStaff?.id
        )
        .sort((a, b) => getFullName(a).localeCompare(getFullName(b)))
    },
    handleStaffChanged(staffId) {
      this.$emit('change', staffId)
    },
    getName(staffMember) {
      let name = getFullName(staffMember)

      if (staffMember.staff_number) {
        name += ` #${staffMember.staff_number}`
      }

      return name
    }
  }
}
</script>

<style scoped></style>
