<template>
  <th-wrapper
    :title="$t('common.titles.general_info.title')"
    body-class="p-8 pb-3"
    :data-testid="loadingConnectedStaff ? 'loading-connected-staff' : undefined"
  >
    <el-row :gutter="20">
      <!-- First name -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="firstname"
          :label="$t('pages.settings.users.display.user.firstname.title')"
        >
          <el-input
            id="firstname"
            v-model="form.firstname"
            :placeholder="
              $t('pages.settings.users.display.user.firstname.title')
            "
          />
        </el-form-item>
      </el-col>

      <!-- Last name -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="lastname"
          :label="$t('pages.settings.users.display.user.lastname.title')"
        >
          <el-input
            id="lastname"
            v-model="form.lastname"
            :placeholder="
              $t('pages.settings.users.display.user.lastname.title')
            "
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <!-- Locations -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          for="locations"
          :label="$t('pages.settings.users.display.branches.title')"
        >
          <available-in
            id="locations"
            v-loading="resourceLoading"
            hide-available-nowhere
            :model-value="{
              locations: form.locations,
              branch_groups: form.branch_groups
            }"
            :resources="resources"
            :show-items-limit="2"
            @update:modelValue="handleAvailableInInput"
          />
        </el-form-item>
      </el-col>

      <!-- Staff profile -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          :label="$t('pages.settings.users.display.staff_connect.title')"
        >
          <div v-loading="loadingConnectedStaff" class="inline-block w-full">
            <div v-if="connectedStaff" class="flex items-center">
              <router-link
                :to="{
                  name: 'staff-edit',
                  params: { id: connectedStaff.id }
                }"
                class="text-th-primary-blue hover:underline leading-relaxed"
              >
                {{ $formatStaff(connectedStaff, ['fullName', 'staff_number']) }}
              </router-link>

              <el-button
                plain
                class="flex-shrink-0 el-button--text-icon"
                icon="Delete"
                @click="() => (connectedStaff = null)"
              />
            </div>
            <connect-staff
              v-else
              :initially-connected-staff="pristineConnectedStaff"
              @change="(member) => (connectedStaff = member)"
            />
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import compare from 'just-compare'
import isEmpty from 'just-is-empty'
import AvailableIn from '@/components/available-in'
import ConnectStaff from './connect-staff'

export default {
  components: {
    AvailableIn,
    ConnectStaff
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      resourceLoading: false,
      loadingConnectedStaff: false,
      resources: {},
      pristineConnectedStaff: null,
      connectedStaff: null
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    userId() {
      return this.$route.params.id
    }
  },

  mounted() {
    this.fetchResources()
    if (this.userId) this.fetchConnectedStaff()
  },

  methods: {
    isDirty() {
      return !compare(this.connectedStaff, this.pristineConnectedStaff)
    },

    async fetchConnectedStaff() {
      this.loadingConnectedStaff = true
      // Try to find connected staff
      try {
        const { staff = [] } = await this.$resourceFetch({
          resource: 'staff',
          query: { user: this.userId }
        })
        this.connectedStaff = staff[0] || null
        this.pristineConnectedStaff = this.$deepClone(this.connectedStaff)
      } catch (error) {
        this.$logException(error, { trackError: false })
      } finally {
        this.loadingConnectedStaff = false
      }
    },

    async fetchResources() {
      this.resourceLoading = true
      try {
        const {
          branchesV1 = [],
          branchGroups = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups')
        this.resources = { branches: branchesV1, branchGroups }
      } catch (error) {
        this.$logException(error, { trackError: false })
      } finally {
        this.resourceLoading = false
      }
    },

    handleAvailableInInput({ locations, branch_groups }) {
      this.form.locations = !isEmpty(locations) ? locations : null //prevent empty array to be saved (aka AvailableNowhere)
      this.form.branch_groups = branch_groups
    },

    /**
     * Deletes user/staff relation
     */
    async deleteStaffConnection() {
      if (this.pristineConnectedStaff?.id) {
        await th.staff().makeUser(this.pristineConnectedStaff.id, {
          user: null
        })
      }
    },

    /**
     * Checks if the connected staff member has changed, and updates the (dis-)connected staff member accordingly.
     * @param {string} userId - user ID
     * @returns {Promise<void>}
     */
    async updateStaffConnection(userId) {
      if (!this.isDirty()) {
        return
      }

      try {
        // As the new connectedStaff is different that the current one
        // First we need to unassign the user from the previous staff, setting the user to null
        // Second we need to assign the user to the new staff if selected
        if (this.pristineConnectedStaff?.id) {
          await th
            .staff()
            .makeUser(this.pristineConnectedStaff.id, { user: null })
        }

        if (this.connectedStaff) {
          // new connected staff
          await th.staff().makeUser(this.connectedStaff.id, { user: userId })
          this.pristineConnectedStaff = this.$deepClone(this.connectedStaff)
        } else {
          this.pristineConnectedStaff = null
        }
      } catch (error) {
        this.$logException(error, { trackError: false })
      }
    }
  }
}
</script>
