<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <user-form ref="form" class="m-8" />
  </th-page-wrapper>
</template>

<script>
import UserForm from './components/form'

export default {
  components: {
    UserForm
  },

  metaInfo() {
    return {
      title: this.$t('common.resource.user.singular')
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },

    actions() {
      return {
        permissionPrefix: 'settings:users',
        returnedPath: { name: 'settings-users' },
        isNew: this.isNew
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },

    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
