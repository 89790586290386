<template>
  <th-wrapper
    :title="$t('pages.settings.users.display.permissions.title')"
    :subtitle="$t('pages.settings.users.display.permissions.subtitle')"
    body-class="p-8 pb-3"
    collapsable
  >
    <el-row :gutter="20">
      <!-- Permissions template -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="scopes"
          :label="
            $t(
              'pages.settings.users.display.permissions.permissions_template.title'
            )
          "
        >
          <user-permissions
            ref="user-permissions"
            v-model="form.user_permission_template_id"
            :permissions-templates="userPermissionsTemplates"
            :template-names-array="templateNamesArray"
            :resources="{ userPermissionsTemplates }"
            @refetch-templates="fetchPermissionsTemplates"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import UserPermissions from './user-permissions'
import { setPermissionTemplateNamesArray } from './helpers'

export default {
  components: {
    UserPermissions
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      userPermissionsTemplates: []
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    templateNamesArray() {
      return setPermissionTemplateNamesArray(this.userPermissionsTemplates)
    }
  },

  watch: {
    'model.user_permission_template_id'() {
      this.fetchPermissionsTemplates()
    }
  },

  mounted() {
    this.fetchPermissionsTemplates()
  },

  methods: {
    async fetchPermissionsTemplates() {
      try {
        const { userPermissionsTemplates } = await this.$resourceFetch(
          'userPermissionsTemplates'
        )
        this.userPermissionsTemplates = userPermissionsTemplates
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.settings.users.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    }
  }
}
</script>
