<template>
  <el-form ref="form" :model="user" :rules="rules">
    <!-- General information -->
    <user-general ref="general" v-model="user" v-loading="loading" />

    <!-- Dashboard login -->
    <user-dashboard-login v-if="user.user" v-model="user" v-loading="loading" />

    <!-- Dashboard permissions -->
    <user-dashboard-permissions v-model="user" v-loading="loading" />
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import UserGeneral from './user-general'
import UserDashboardLogin from './user-dashboard-login'
import UserDashboardPermissions from './user-dashboard-permissions'
import { isEmptyArray, isEmptyObject } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import fullName from '@utils/full-name'

function genInitialState() {
  return {
    user: {
      id: null,
      email: null
    },
    description: null,
    active: true,
    user_id: null,
    scopes: null,
    username: null,
    secret: null,
    locations: null,
    branch_groups: null,
    firstname: null,
    lastname: null,
    user_permission_template_id: null
  }
}

function makeHandleableUsersBody(payload) {
  return Object.keys(payload).reduce((result, key) => {
    // replace empty objects, empty arrays and empty strings with null
    // exception locations and branch_groups, which can be an empty array to signify "nowhere"
    const isEmpty =
      (isEmptyObject(payload[key]) ||
        isEmptyArray(payload[key]) ||
        isEmptyString(payload[key])) &&
      key !== 'locations' &&
      key !== 'branch_groups'
    result[key] = isEmpty ? null : payload[key]
    return result
  }, {})
}

export default {
  components: {
    UserGeneral,
    UserDashboardLogin,
    UserDashboardPermissions
  },

  data() {
    return {
      loading: false,
      user: genInitialState()
    }
  },

  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    userId() {
      return this.$route.params.id
    },

    isNew() {
      return !this.$route.params.id
    },

    configurationId() {
      return safeGet(this.clientAccountConfiguration, 'id', null)
    },

    rules() {
      return {
        firstname: [
          {
            required: true,
            message: this.$t(
              'pages.settings.users.edit.form.firstname.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        lastname: [
          {
            required: true,
            message: this.$t(
              'pages.settings.users.edit.form.lastname.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        'user.email': [
          {
            required: true,
            message: this.$t(
              'pages.settings.users.edit.form.email.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: this.$t(
              'pages.customers.edit.form.field_warnings.type.email'
            )
          },
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        secret: [
          {
            required: this.isNew,
            message: this.$t(
              'pages.settings.users.edit.form.password.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            min: 5,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.min_length',
              { length: 5 }
            ),
            trigger: 'change'
          }
        ]
      }
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      if (this.isNew) {
        return
      }

      this.loading = true
      try {
        const { data = {} } = await th
          .users(this.configurationId)
          .get(this.userId)
        this.user = pick(data, Object.keys(genInitialState()))
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.user.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    async submitForm() {
      // We don't have a dedicated username input field currently,
      // but we want the email to be the username so we are assigning it.
      this.user.username = this.user.user.email

      const valid = await this.validate()
      if (!valid) {
        return this.$notify.warning({
          title: this.$t(
            'pages.settings.users.edit.form.warnings.invalid_inputs.title'
          ),
          message: this.$t(
            'pages.settings.users.edit.form.warnings.invalid_inputs.contents'
          )
        })
      }

      if (this.isNew) return this.create()
      this.alter()
    },

    async alter() {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.user.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.user.singular')
      })

      try {
        this.loading = true
        const payload = makeHandleableUsersBody(this.user)
        const { data = {} } = await th
          .users(this.configurationId)
          .put(this.userId, payload)

        if (!data.id) {
          throw new Error(errorMessage)
        }

        await this.$refs.general.updateStaffConnection(data.id)

        this.user = pick(data, Object.keys(genInitialState()))

        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    async create() {
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.user.singular')
      })
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.user.singular')
      })

      try {
        this.loading = true
        const payload = makeHandleableUsersBody(this.user)
        const { data = {} } = await th
          .users(this.configurationId)
          .create(payload)

        if (!data.id) {
          throw new Error(errorMessage)
        }

        await this.$refs.general.updateStaffConnection(data.id)

        this.user = pick(data, Object.keys(genInitialState()))

        this.$message({
          type: 'success',
          message: successMessage
        })

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'settings-users-edit',
            params: { id: data.id }
          })
        } else {
          this.$router.push({
            name: 'settings-users'
          })
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    async handleDelete() {
      const confirm = await this.$askToDelete(
        fullName(this.user) || this.user.id
      )
      if (confirm) this.delete()
    },

    async delete() {
      this.loading = true
      try {
        await this.$refs.general.deleteStaffConnection() // Delete user/staff relation
        await th.users(this.configurationId).delete(this.userId)

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.delete.single', {
            resource: this.$t('common.resource.user.singular')
          })
        })

        this.$router.push({ name: 'settings-users' })
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t('common.resource.user.singular')
          })
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
