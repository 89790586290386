<template>
  <th-wrapper
    :title="$t('pages.settings.users.display.login_details.title')"
    :subtitle="$t('pages.settings.users.display.password.edit.hint.content')"
    body-class="p-8 pb-3"
    collapsable
  >
    <el-row :gutter="20">
      <!-- Organization -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="organisation"
          :label="$t('pages.settings.users.display.organisation.title')"
        >
          <el-input id="organisation" v-model="organizationName" disabled />
        </el-form-item>
      </el-col>

      <!-- Active -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="active"
          class="h-10 mt-5 xs:h-auto xs:mt-0 xs:mb-4 flex items-center"
        >
          <el-checkbox
            id="active"
            v-model="form.active"
            :label="$t('pages.settings.users.display.user.active')"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <!-- Email -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="user.email"
          :label="$t('pages.settings.users.display.email.title')"
        >
          <el-input
            id="user.email"
            v-model="form.user.email"
            :placeholder="$t('pages.settings.users.display.email.title')"
          />
        </el-form-item>
      </el-col>

      <!-- Password -->
      <el-col :lg="8" :md="12" :sm="12">
        <el-form-item
          prop="secret"
          :label="$t('pages.settings.users.display.password.title')"
        >
          <el-input
            id="secret"
            v-model="form.secret"
            autocomplete="new-password"
            placeholder="●●●●●●●●●●●●"
            :type="showPassword ? 'text' : 'password'"
          >
            <template #append>
              <el-button icon="View" @click="showPassword = !showPassword" />
            </template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showPassword: false
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    organizationName() {
      return safeGet(this.$store, 'state.Auth.orgName') || null
    }
  }
}
</script>
