export const defaultScopes = [
  'staff',
  'staff:read',
  'staff:create',
  'staff:update',
  'staff:delete',
  'staff:groups:read',
  'staff:groups:create',
  'staff:groups:update',
  'staff:groups:delete',
  'customers',
  'customers:read',
  'customers:create',
  'customers:update',
  'customers:delete',
  'products',
  'products:read',
  'products:create',
  'products:update',
  'products:delete',
  // 'products:product_manager:read',
  // 'products:product_manager:create',
  // 'products:product_manager:update',
  // 'products:product_manager:delete',
  'products:product_groups:read',
  'products:product_groups:create',
  'products:product_groups:update',
  'products:product_groups:delete',
  'products:product_templates:read',
  'products:product_templates:create',
  'products:product_templates:update',
  'products:product_templates:delete',
  'products:products_services:read',
  'products:products_services:create',
  'products:products_services:update',
  'products:products_services:delete',
  'products:discounts:read',
  'products:discounts:create',
  'products:discounts:update',
  'products:discounts:delete',
  'products:tags:read',
  'products:tags:create',
  'products:tags:update',
  'products:tags:delete',
  'reports:read',
  'reports:financial_accounting:read',
  'reports:statistics:read',
  'reports:exports_manager:read',
  // 'inventory:read',
  // 'inventory:outgoing_orders:read',
  // 'inventory:put_away:read',
  // 'inventory:dispositions:read',
  // 'inventory:financial_accounting:read',
  'accounting:read',
  'vouchers:read',
  'vouchers:create',
  'vouchers:update',
  'vouchers:delete',
  'vouchers:list:read',
  'vouchers:logs:read',
  'resources:read',
  'resources:branches:read',
  'resources:branches:create',
  'resources:branches:update',
  'resources:branches:delete',
  'resources:warehouses:read',
  'resources:warehouses:create',
  'resources:warehouses:update',
  'resources:warehouses:delete',
  'resources:registers:read',
  'resources:registers:create',
  'resources:registers:update',
  'resources:registers:delete',
  'resources:devices:read',
  'resources:devices:create',
  'resources:devices:update',
  'resources:devices:delete',
  'resources:licenses:read',
  'resources:licenses:create',
  'resources:licenses:update',
  'resources:licenses:delete',
  'utilities:read',
  'utilities:document_designer:read',
  'utilities:document_designer:create',
  'utilities:document_designer:update',
  'utilities:document_designer:delete',
  'utilities:audits:read',
  'utilities:cms:read',
  'utilities:cms:create',
  'utilities:cms:update',
  'utilities:cms:delete',
  'utilities:custom_dashboard:read',
  'utilities:custom_dashboard:create',
  'utilities:custom_dashboard:update',
  'utilities:custom_dashboard:delete',
  'utilities:notifications:read',
  'utilities:notifications:create',
  'utilities:notifications:update',
  'utilities:notifications:delete',
  'utilities:favourites:read',
  'utilities:favourites:create',
  'utilities:favourites:update',
  'utilities:favourites:delete',
  'settings:read',
  'settings:general:read',
  'settings:general:create',
  'settings:general:update',
  'settings:general:delete'
]

export const basePermissions = {
  // accountant: {
  //   title: 'pages.settings.users.edit.form.scopes.accountant.title',
  //   scopes: [
  //   ]
  // },
  // store_manager: {
  //   title: 'pages.settings.users.edit.form.scopes.store_manager.title',
  //   scopes: [
  //     'staff:read',
  //     'staff:create',
  //     'staff:update',
  //     'staff:delete',
  //     'staff:groups:read',
  //     'staff:groups:create',
  //     'staff:groups:update',
  //     'staff:groups:delete'
  //   ]
  // },
  // regional_manager: {
  //   title: 'pages.settings.users.edit.form.scopes.regional_manager.title',
  //   scopes: []
  // }
}

// export const createTemplatePreset = {
//   value: 'create_template',
//   label: 'pages.settings.users.form.scopes.preset.create_template'
// }

export const childrenReportsCategories = [
  'reports_financial_accounting',
  'reports_statistics',
  'reports_exports_manager'
]
